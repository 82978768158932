import React, { Component } from "react";
import { connect } from "react-redux";
import axios from 'axios';
import {
  saveStep1Data,
  validateZipCode,
  saveFormConfig,
  getCustomerClass
} from "../actions/paynowActions";
import {
  fetchMultipleLatestBill,
  generateFpHash,
} from "../actions/dashboardActions";
import { showMessage } from "../actions/popupMessageActions";
import Step1 from "../components/paynowStep1";
import Step2 from "../components/paynowStep2";
import Step3 from "../components/paynowStep3";

import Header from "../partials/header";
import Footer from "../partials/footer";
import { Redirect } from "react-router-dom";

class PayNow extends Component {
  constructor() {
    super();
    this.state = {
      step1: true,
      step2: false,
      step3: false,
      child: Step1,
      accountId: "",
      showMessage: "none",
      resultMessage: "",
      showSample: false,
      isLoading: false,
      isSignInSuccess: false,
      btnSubmitValue: "Continue",
      paymentFormData: {
        accountId: "",
        x_login: "",
        x_fp_sequence: "",
        x_fp_timestamp: "",
        x_amount: "",
        x_fp_hash: "",
        x_invoice_num: "",
        x_po_num: "",
        x_show_form: "PAYMENT_FORM",
        x_relay_response: "TRUE",
        Checkout: "Pay Now",
      },
      paymentProcessing: false,
      displayLogin: false,
      disablePayNow: false,
      isSystemMaintenanceLoading: false,
    };
    this.handleSubmit.bind(this);
    this.handleCancel.bind(this);
  }
  async executeRequests(postData) {
    let result1 = await this.props.fetchMultipleLatestBill(postData);
    if (result1) {
      this.setState({
        child: Step2,
        step1: false,
        step2: true,
        step3: false,
      });
      this.props.saveFormConfig({ btnSubmitValue: "Continue" });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      showMessage: "none",
      resultMessage: "",
    });
    let saveStep1Data = new Promise((resolve, reject) => {
      this.props.saveStep1Data("validated", true);
      resolve("done");
    });
    saveStep1Data.then(
      (result) => {
        if (this.state.step1 === true) {
          if (
            !(
              this.props.paynow.step1Data.accountId === "" ||
              this.props.paynow.step1Data.zipcode === ""
            )
          ) {
            this.props.saveFormConfig({ btnSubmitValue: "Please wait..." });
            this.props
              .validateZipCode(
                this.props.paynow.step1Data.accountId,
                this.props.paynow.step1Data.zipcode
              )
              .then((success) => {
                if (success) {
                  const result = this.props.paynow.validateZipCodeRes.result;
                  if (result.status === "Success") {
                    const postData = [
                      [
                        this.props.paynow.step1Data.accountId,
                        "service location here",
                      ],
                    ];
                    this.executeRequests(postData);
                  } else {
                    this.setState({
                      showMessage: "block",
                      resultMessage: result.details,
                    });
                    this.props.saveFormConfig({ btnSubmitValue: "Continue" });
                  }
                }
              })
              .catch((error) => {
                this.props.showMessage(true, "Server Error. Try again later!");
                this.props.saveFormConfig({ btnSubmitValue: "Continue" });
              });
          }
        } else if (this.state.step2 === true) {
          this.props.saveFormConfig({ btnSubmitValue: "Please wait..." });
          const myInterval = setInterval(() => {
            console.log("running");
            if (this.props.dashboard.orderData.accountSummary.length > 0) {
              clearInterval(myInterval);
              this.setState({
                step1: false,
                step2: false,
                step3: true,
                child: Step3,
              });
            }
          }, 500);
        } else if (this.state.step3 === true) {
          const item = this.props.dashboard.orderData.accountSummary[0];
          if(item.amountToBePaid<1 || item.amountToBePaid>99999.99){
            this.props.showMessage(true, "Please enter a valid amount of $1.00 to $99,999.99!");
            return;
          }

          this.props.saveFormConfig({ btnSubmitValue: "Please wait..." });
          this.props.getCustomerClass({acountId: this.props.dashboard.orderData.accountSummary[0]}).then((response)=>{
              this.props.dashboard.orderData.accountSummary[0].className = this.props.paynow.customerClass.class;
              console.log("before payonclick", this.props.dashboard.orderData.accountSummary[0])
              this.payOnClick(this.props.dashboard.orderData.accountSummary[0]);
          })
        }
      },
      (error) => this.props.showMessage(true, error)
    );
  };
  payOnClick = (item) => {
    if (item.amountToBePaid != "" || item.amountToBePaid>=1 && item.amountToBePaid<=99999.99) {
      this.setState(
        {
          paymentProcessing: true,
        },
        () => {
          this.props.generateFpHash(item).then((response) => {
            // console.log("response",response)
            this.setState(
              {
                ...this.state,
                paymentFormData: {
                  ...this.state.paymentFormData,
                  accountId: item.accID,
                  x_login: response.login,
                  x_fp_sequence: response.fp_sequence,
                  x_fp_timestamp: response.fp_timestamp,
                  x_amount: item.amountToBePaid,
                  x_fp_hash: response.fp_hash,
                  x_invoice_num: response.fp_sequence,
                  x_po_num: item.fullName,
                  x_show_form: "PAYMENT_FORM",
                  x_relay_response: "TRUE",
                  Checkout: "Pay Now",
                },
              },
              () => {
                document.getElementById("payment-form-" + item.accID).submit();
                console.log(
                  "this.state.paymentFormData",
                  this.state.paymentFormData
                );
              }
            );
          });
        }
      );
    } else {
      this.props.showMessage(true, "Please enter a valid amount $1.00 to $99,999.99!");
    }
  };

  handleCancel = (e) => {
    e.preventDefault();
    document.documentElement.scrollTop = 0;
    if (this.state.step1 === true) {
      this.setState({
        displayLogin: true,
      });
    } else if (this.state.step2 === true) {
      this.setState({
        step1: true,
        step2: false,
        step3: false,
        child: Step1,
      });
    } else if (this.state.step3 === true) {
      this.setState({
        step1: false,
        step2: true,
        step3: false,
        child: Step2,
      });
    }
  };

  componentWillMount() {
    this.systeMaintenance();
  }

  systeMaintenance = () => {
    this.setState({
      isSystemMaintenanceLoading: true,
    });
    return axios
    .get('api/v1/system-maintenance', 
    {
        headers: {'Content-Type':'application/json'}
    })
    .then(response => {
      
      // Update Quick Pay URL Control
      if(response.data[1].flag == 1) {
          this.setState({
            disablePayNow: true,
          });
      }else{
          this.setState({
            disablePayNow: false,
            isSystemMaintenanceLoading: false,
          });
      }
    });
  }

  render() {
    const btnSubmitValue = this.props.paynow.formConfig.btnSubmitValue;
    const btnCancelValue =
      this.state.step1 === true ? "Back To Home" : "Cancel";
    const btnCancelAllignment = this.state.step1 === true ? "left" : "center";
    if (this.state.displayLogin || this.state.disablePayNow) {
      return <Redirect to="/" />;
    } else if (this.state.isSystemMaintenanceLoading) {
      return <React.Fragment>
      <Header />
      </React.Fragment>;
    }
    return (
      <React.Fragment>
        <Header />
        <section id="pay_now">
          <div className="container">
            <div id="title" className="row justify-content-md-center">
              <div className="col-lg-12">
                <h4 className="primary-font-color text-center">Quick Pay</h4>
              </div>
            </div>
            <div id="progress" className="row justify-content-md-center">
              <div className="col-lg-12">
                <div className="progress-line">
                  <hr />
                </div>
                <div className="progress-balls">
                  <div
                    className={
                      "step1 " + (this.state.step1 === true ? "show" : "hide")
                    }
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/step1-blue.png"}
                      alt="step-1"
                    ></img>
                    <p className="regular-font-size">Customer Information</p>
                  </div>
                  <div
                    className={
                      "step2 " + (this.state.step2 === true ? "show" : "hide")
                    }
                  >
                    <img
                      src={
                        this.state.step2 === true
                          ? process.env.PUBLIC_URL + "/images/step2-blue.png"
                          : this.state.step3
                          ? process.env.PUBLIC_URL + "/images/step2-blue.png"
                          : process.env.PUBLIC_URL + "/images/step2-grey.png"
                      }
                      alt="step-2"
                    ></img>
                    <p className="regular-font-size">Validation</p>
                  </div>
                  <div
                    className={
                      "step3 " + (this.state.step3 === true ? "show" : "hide")
                    }
                  >
                    <img
                      src={
                        this.state.step3 === true
                          ? process.env.PUBLIC_URL + "/images/step3-blue.png"
                          : process.env.PUBLIC_URL + "/images/step3-grey.png"
                      }
                      alt="step-3"
                    ></img>
                    <p className="regular-font-size">Enter Payment</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sign-up-result"
              style={{ display: this.state.showMessage }}
            >
              <div className="col-lg-12 text-center">
                <label className="secondary-font-color font-bold">
                  {this.state.resultMessage}
                </label>
              </div>
            </div>
            <div
              id="paynow-form"
              className={this.state.showMessage === "block" ? "mt-0" : ""}
            >
              {React.createElement(this.state.child)}
              <div id="gpa-form-actions" className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                  <div className="row">
                    <div id="proceed" className="col-lg-6 order-lg-last">
                      {
                        // console.log("this.props.paynow.formConfig.isLoadingthis.props.paynow.formConfig.isLoading",this.props.paynow.formConfig)
                        // this.props.paynow.formConfig.isLoading ?
                        // <button id="submit" type="submit" className="btn btn-primary gpa-form-button regular-font-size font-bold" onClick={this.handleSubmit} disabled={true}>{btnSubmitValue}</button>
                        // :
                        // <button id="submit" type="submit" className="btn btn-primary gpa-form-button regular-font-size font-bold" onClick={this.handleSubmit} disabled={false}>{btnSubmitValue}</button>
                      }
                      {!this.state.step3 ? (
                        <button
                          id="submit"
                          type="submit"
                          className="btn btn-primary gpa-form-button regular-font-size font-bold"
                          onClick={this.handleSubmit}
                          disabled={btnSubmitValue != "Continue" ? true : false}
                        >
                          {btnSubmitValue}
                        </button>
                      ) : (
                        // <form id={"payment-form-"+this.props.dashboard.orderData.accountSummary[0].accID} action="https://demo.globalgatewaye4.firstdata.com/payment" method="post">

                        <form
                          id={
                            "payment-form-" +
                            this.props.dashboard.orderData.accountSummary[0]
                              .accID
                          }
                          action="https://checkout.globalgatewaye4.firstdata.com/payment"
                          method="post"
                        >
                          <input
                            name="x_login"
                            value={this.state.paymentFormData.x_login}
                            type="hidden"
                          />
                          <input
                            name="x_fp_sequence"
                            value={this.state.paymentFormData.x_fp_sequence}
                            type="hidden"
                          />
                          <input
                            name="x_fp_timestamp"
                            value={this.state.paymentFormData.x_fp_timestamp}
                            type="hidden"
                          />
                          <input
                            name="x_amount"
                            value={this.state.paymentFormData.x_amount}
                            type="hidden"
                          />
                          <input
                            name="x_fp_hash"
                            value={this.state.paymentFormData.x_fp_hash}
                            type="hidden"
                          />
                          <input
                            name="x_invoice_num"
                            value={this.state.paymentFormData.x_invoice_num}
                            type="hidden"
                          />
                          <input
                            name="x_po_num"
                            value={this.state.paymentFormData.x_po_num}
                            type="hidden"
                          />
                          <input
                            name="x_show_form"
                            value={this.state.paymentFormData.x_show_form}
                            type="hidden"
                          />
                          <input
                            name="x_relay_response"
                            value={this.state.paymentFormData.x_relay_response}
                            type="hidden"
                          />
                          <input
                            type="button"
                            name="Checkout"
                            style={{ margin: "0" }}
                            value={btnSubmitValue}
                            className="btn btn-primary gpa-form-button regular-font-size font-bold"
                            onClick={this.handleSubmit}
                            disabled={this.state.paymentProcessing}
                          />
                        </form>
                      )}
                    </div>
                    <div
                      id="cancel"
                      className="col-lg-6 order-lg-first"
                      style={{ textAlign: btnCancelAllignment }}
                    >
                      <a
                        href="/"
                        className="dark-font-color regular-font-size"
                        onClick={this.handleCancel}
                      >
                        <u>{btnCancelValue}</u>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>

            <div id="bottom-text">
              <label
                className="font-bold"
                style={
                  this.state.step1 === true
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <span className="secondary-font-color custom-line-height">
                  *
                </span>{" "}
                Information should match your billing statement
              </label>
            </div>
            <br />
            <br />
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  paynow: state.paynow,
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  saveStep1Data,
  validateZipCode,
  fetchMultipleLatestBill,
  saveFormConfig,
  showMessage,
  generateFpHash,
  getCustomerClass
})(PayNow);
